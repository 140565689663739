import React from "react";
import { Link } from "gatsby";

// components
import Logo from "./Logo";

// assets
import imageLogo from "../../assets/logos/logo-alternate.svg";
import ibpLogo from "../../assets/logos/logo-ibp-group.svg";
interface Props {
  readonly [key: string]: {
    [key: string]: any;
  };
}

const footerConfigs: Props = {
  imageRights: {
    item: "here",
    url: "../../downloads/files/Conex Banninger Image Right 2023 Valves RUS case study.pdf",
  },
  footerNavigation: [
    {
      item: "Privacy Policy",
      url: "/privacy",
    },
  ],
  social: [
    {
      item: "LinkedIn",
      url: "https://www.linkedin.com/company/78371253",
      icon: "linkedin",
    },
  ],
};

// markup
const Footer: React.FC = () => {
  return (
    <footer id="footer" className="c-footer">
      <section className="uk-section uk-section-small uk-background-secondary uk-preserve-color">
        <div className="uk-container uk-text-center">
          <div
            className="uk-grid uk-grid-small uk-child-width-2-3@xl uk-child-width-1-1 uk-light"
            data-uk-margin="margin: uk-margin-small-top"
          >
            <div className="c-nav__logo ">
              <Logo image={imageLogo} title="Conex Bänninger" />
            </div>
            <div className="c-text-2xsmall uk-width-2-3@s uk-width-1-1 uk-margin-auto c-color-grey-400">
              <p className="uk-margin-small-top">
                Conex Bänninger is the recognised leader in copper press
                fittings in the plumbing and HVAC-R industries in Australia.
              </p>
              <p>
                Disclaimer - The marks CONEX BÄNNINGER, CONEX, BÄNNINGER,
                &gt;B&lt; and &gt;B&lt; PRESS are trademarks of Conex Universal
                Limited and Conex IPR Limited and may not be used without
                permission of those companies. Images and content across this
                site is representative of the Conex Bänninger brand and product
                range. All content on this site is used in agreement with Conex
                Universal Ltd.
              </p>
              <p>
                More information{" "}
                <span>
                  <a href={footerConfigs.imageRights.url}>
                    {footerConfigs.imageRights.item}
                  </a>
                </span>
              </p>
              {footerConfigs.footerNavigation && (
                <ul className="uk-light uk-subnav-divider uk-flex-center uk-text-center uk-margin-small">
                  {footerConfigs.footerNavigation.map(
                    (data: any, index: number) => (
                      <li key={`footerNav${index}`} className="uk-flex-center">
                        <Link to={data.url}>{data.item}</Link>
                      </li>
                    )
                  )}
                </ul>
              )}
              {footerConfigs.social && (
                <ul className="uk-light uk-subnav uk-subnav-divider uk-flex-center uk-margin-small c-visually__hidden">
                  {footerConfigs.social.map((data: any, index: number) => (
                    <li key={`footerSocial${index}`}>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        title={data.item}
                        href={data.url}
                      >
                        <img
                          width=""
                          height=""
                          alt=""
                          data-uk-img={`data-src: /icons/icon-${data.icon}.svg`}
                          className="uk-svg"
                        />
                        <span className="c-visually__hidden">
                          {data.item} social account
                        </span>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="uk-section uk-section-default uk-padding-remove-vertical">
        <div className="uk-container uk-text-center">
          <a href="https://www.ibpgroup.com/" target="_blank" rel="noreferrer">
            <img
              src={ibpLogo}
              alt="an IBP Group company"
              style={{ margin: "15px" }}
            />
          </a>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
