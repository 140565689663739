import * as React from "react";
import { Link } from "gatsby";

// Components
import LogoPrimary from "../../assets/logos/logo.svg";

interface Props {
  url?: string | null;
  image?: string | null;
  title?: string | null;
  width?: number | null;
}

const Logo: React.FC<Props> = ({ url, image, title, width }) => {
  const configs = {
    url: url || "/",
    image: image || `${LogoPrimary}`,
    title: title || `Conex Bänninger`,
    width: width || `${200}`,
  };

  return (
    <Link
      to={configs.url}
      className="uk-display-inline-block"
      title={configs.title}
    >
      <img src={configs.image} alt={configs.title} width={configs.width} />
    </Link>
  );
};

export default Logo;
