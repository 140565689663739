import React from "react";

interface PropsItem {
  brandHeading?: string | null;
  brandMediaUrl?: string | null;
  brandMediaTitle?: string | undefined;
  brandMediaWidth?: string | undefined;
  productImageUrl: string;
  productImageTitle: string;
  pageUrl: string;
  customClass?: string | undefined;
}

const FeatureProductItem: React.FC<PropsItem> = (props) => {
  const {
    brandHeading,
    brandMediaUrl,
    brandMediaTitle,
    brandMediaWidth,
    productImageUrl,
    productImageTitle,
    pageUrl,
    customClass,
    children,
  } = props;

  return (
    <li
      className={
        customClass
          ? customClass
          : "uk-width-2-3 uk-width-2-5@s uk-width-2-5@m uk-width-1-4@l"
      }
    >
      <div className="uk-panel">
        <div
          className="c-feature-product__brand uk-margin-bottom uk-flex uk-flex-bottom"
          style={{ width: brandMediaWidth }}
        >
          {brandHeading && (
            <span
              className={`c-font-dax c-feature-product__brand-heading uk-margin-remove`}
            >
              {brandHeading}
            </span>
          )}
          {brandMediaUrl && (
            <a href={pageUrl}>
              <img
                alt={brandMediaTitle}
                src={brandMediaUrl}
                className="uk-width-1-1"
              />
            </a>
          )}
        </div>
        {productImageUrl && (
          <a href={pageUrl} className="uk-animation-toggle">
            <div className="uk-overflow-hidden">
              <img
                src={productImageUrl}
                alt={productImageTitle}
                className="uk-animation-kenburns"
              />
            </div>
          </a>
        )}
        <div className="uk-margin-small-top">
          {/* Children */}
          {children}
        </div>
      </div>
    </li>
  );
};

export default FeatureProductItem;
