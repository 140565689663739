import React from "react";
import { Link } from "gatsby";

interface Props {
  title: string;
  url: string;
  navigation: React.ReactNode;
  quicklinks: React.ReactNode;
}

const NavbarDropdown: React.FC<Props> = ({
  title,
  url,
  navigation,
  quicklinks,
}) => {
  return (
    <li>
      <button>
        <span className="c-visually__hidden">Toggle Menu for:</span> {title}
      </button>
      <div
        className="uk-navbar-dropdown uk-drop uk-drop-boundary uk-navbar-dropdown-dropbar uk-drop-bottom-center"
        uk-drop="mode: click; boundary: !section; boundary-align: true; pos: bottom-justify;"
      >
        <div className="uk-container c-navigation__dropdown">
          <h1 className="c-visually__hidden">Conex Bänninger</h1>
          <h2 className="uk-text-default uk-margin-remove">
            <Link
              to={url}
              className="uk-text-bold uk-width-1-1 c-navigation__heading uk-visible@l"
            >
              {title}{" "}
              <span data-uk-icon="icon: chevron-right; ratio: 0.8"></span>
            </Link>
          </h2>
          <div
            className="uk-navbar-dropdown-nav uk-grid-medium"
            data-uk-grid=""
          >
            <div className="uk-width-3-4@l uk-width-2-3@xl uk-width-1-1">
              <div className="uk-width-1-1">
                <div className="c-navigation__list">
                  <div
                    className="uk-child-width-1-2@l uk-child-width-1-1 uk-grid-medium"
                    data-uk-grid=""
                  >
                    {navigation}
                  </div>
                </div>
              </div>
            </div>
            <div className="uk-width-1-4@l uk-width-1-3@xl uk-width-1-1 uk-margin-remove">
              <h3 className="c-visually__hidden">Quick Links</h3>
              <div className="c-navigation__list">
                <div
                  className="uk-child-width-1-1 uk-grid-small"
                  data-uk-grid=""
                >
                  {quicklinks}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default NavbarDropdown;
