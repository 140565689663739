import React from "react";
import "./Styles.scss";

const SkipLinks: React.FC = () => {
  return (
    <div className="skip-link__link_wrap">
      <a href="#main" className="skip-link__link">
        Skip to main content
      </a>
    </div>
  );
};

export default SkipLinks;
