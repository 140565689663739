import React from "react";
import { Link } from "gatsby";
import { Formik, Form, Field, FormikErrors } from "formik";

// components
import FeatureProductItem from "../FeatureProduct/Item";
import Icons from "../Icons/Index";
import ModalWrapper from "../Modal/Index";
import FormLabel from "../Forms/Label";

// assets
import results from "./ApplicationBrands";
import applicationOptions from "./ApplicationOptions";

// Shape of form values
interface FormValues {
  type: string;
  pressure: number;
  temp: number;
}

declare global {
  interface Window {
    dataLayer: any;
  }
}

interface IProps {}

// State Properties
interface IState {
  results: ResultProps[] | [];
  filteredResults: ResultProps[] | [];
  submission?: boolean;
  type?: string | "";
  pressure?: string | "";
  temp?: string | "";
  note?: string | "";
  displayHighTemperature?: string | "";
  displayWater?: string | "";
  displayGas?: string | "";
  displayRefrigerant?: string | "";
  lowerTemperature?: string | "";
  midTemperature?: string | "";
  limitTemperature?: string | "";
  lowerPressure?: string | "";
  limitPressure?: string | "";
  exclude?: string | "";
  condition?: string | "";
}

interface ResultProps {
  brand: string;
  brandMediaUrl: string;
  brandMediaTitle: string;
  brandMediaWidth: string;
  imageSrc: string;
  imageAlt: string;
  displayWater: boolean;
  displayGas: boolean;
  displayCold: boolean;
  displayTemp: boolean;
  fittingMaterial: string;
  minTempRange: string;
  maxTempRange: string;
  maxPressure: string;
  sizeRange: string;
  url: string;
  query: string;
}

const initialState: IState = {
  results: results,
  filteredResults: [],
  submission: false,
  type: "",
  pressure: "",
  temp: "",
  note: "",
  displayHighTemperature: "",
  displayWater: "",
  displayGas: "",
  displayRefrigerant: "",
  lowerTemperature: "",
  midTemperature: "",
  limitTemperature: "",
  lowerPressure: "",
  limitPressure: "",
  exclude: "",
  condition: "",
};

class ProductSelectorApp extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = initialState;
    this.updateState = this.updateState.bind(this);
  }

  groupBy(arr: Array<any>, criteria: string): any {
    const newObj = arr.reduce(function (acc: any, currentValue: any) {
      if (!acc[currentValue[criteria]]) {
        acc[currentValue[criteria]] = [];
      }
      acc[currentValue[criteria]].push(currentValue);
      return acc;
    }, {});
    return newObj;
  }

  filterResults(
    arr: Array<any>,
    searchTerm: string,
    itemTerm: string,
    equals?: boolean
  ): any {
    const arrSearchTerm = searchTerm.split(",");
    const obj = arr.filter((item: any) => {
      const condition = (i: string) =>
        !equals ? searchTerm == item[i] : !arrSearchTerm.includes(item[i]);
      if (condition(itemTerm)) {
        return true;
      }
    });
    return obj;
  }

  withinRange(x: string, min: string, max: string): boolean {
    const compare = parseInt(x);
    const minRange = parseInt(min);
    const maxRange = parseInt(max);
    return compare >= minRange && compare <= maxRange;
  }

  setFields(
    target: { [key: string]: any; selectedIndex: any; value: string },
    setFieldValue: (key: string, value: string) => void
  ): void {
    const currentOption = target[target.selectedIndex];
    const dataset = currentOption.dataset;

    // Set the hidden fields with the application type details from the data attributes
    Object.keys(dataset).map((item: string) => {
      const dataValue = dataset[item] || "";
      const dataKey = item;

      setFieldValue(dataKey, dataValue);
      this.updateState(dataKey, dataValue);
    });
  }

  setDataLayer = (
    application: string,
    pressure: string,
    temperature: string
  ): void => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "formSubmitConex",
        formName: "conexProductSelector",
        formFieldTypeOfApplication: application, //dynamically set the selected value
        formFieldPressure: pressure, //dynamically set the selected value
        formFieldTemperature: temperature, //dynamically set the selected value
      });
    }
  };

  resetForm = (): void => {
    this.setState({ ...initialState });
  };

  updateState = (name: string, value: string | boolean): void => {
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  devLog(message: string | boolean): any {
    if (process.env.NODE_ENV === "development") {
      console.log(message);
    }
  }

  ProductSelectorForm(): JSX.Element {
    const groupedEntries = this.groupBy(applicationOptions, "GroupTitle");
    const groupedKeys = Object.keys(groupedEntries);

    return (
      <Formik
        initialValues={{
          type: this.state.type,
          pressure: this.state.pressure,
          temp: this.state.temp,
          note: this.state.note,
          displayHighTemperature: this.state.displayHighTemperature,
          displayWater: this.state.displayWater,
          displayGas: this.state.displayGas,
          displayRefrigerant: this.state.displayRefrigerant,
          lowerTemperature: this.state.lowerTemperature,
          midTemperature: this.state.midTemperature,
          limitTemperature: this.state.limitTemperature,
          lowerPressure: this.state.lowerPressure,
          limitPressure: this.state.limitPressure,
          exclude: this.state.exclude,
          condition: this.state.condition,
        }}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values: any, actions) => {
          actions.setSubmitting(false);
          /*
           * 1. Check if the entered temp is between the selected lower and limit temp
           * 2. Check if the entered pressure is between the selected lower and limit pressure
           * 3. Filter back the results we only need
           * 4. Check if the pressure and temp is within the range of the product
           * 5. Check of any custom excludes of brands
           */
          const {
            type,
            pressure,
            temp,
            lowerPressure,
            limitPressure,
            displayWater,
            displayHighTemperature,
            limitTemperature,
            lowerTemperature,
            displayGas,
            displayRefrigerant,
            exclude,
          } = values;

          const isWithinApplicationTemp = this.withinRange(
            temp,
            lowerTemperature,
            limitTemperature
          );
          const isWithinApplicationPressure = this.withinRange(
            pressure,
            lowerPressure,
            limitPressure
          );

          this.devLog(
            "✅ Checking that the temp is valid for the application..."
          );
          this.devLog(
            `${temp} for ${type} is between ${lowerTemperature} and ${limitTemperature} is ${isWithinApplicationTemp}`
          );
          this.devLog(
            "✅ Checking that the pressure is valid for the application..."
          );
          this.devLog(
            `${pressure} for ${type} is between ${lowerPressure} and ${limitPressure} is ${isWithinApplicationPressure}`
          );
          this.devLog(
            "✅ Checking that the temp and pressure is valid for the products..."
          );

          const checkPressureAndTemp = this.state.results.filter(
            (item: any) => {
              const {
                brand,
                minPressure,
                maxPressure,
                minTempRange,
                maxTempRange,
              } = item;
              const isWithinProductPressure = this.withinRange(
                pressure,
                minPressure,
                maxPressure
              );
              const isWithinProductTemp = this.withinRange(
                temp,
                minTempRange,
                maxTempRange
              );
              this.devLog(
                `${temp} for ${brand} is between ${minTempRange} and ${maxTempRange} is ${isWithinProductTemp}`
              );
              this.devLog(
                `${pressure} for ${brand} is between ${minPressure} and ${maxPressure} is ${isWithinProductPressure}`
              );
              if (
                isWithinApplicationTemp &&
                isWithinApplicationPressure &&
                isWithinProductPressure &&
                isWithinProductTemp
              ) {
                return true;
              }
            }
          );
          // Run display checks
          const displayFlag: any = {
            gas: displayGas,
            cold: displayRefrigerant,
            temp: displayHighTemperature,
            water: displayWater,
          };

          // Check if Display Temp is 1
          const checkTemp =
            displayFlag.temp > 0
              ? this.filterResults(
                  checkPressureAndTemp,
                  displayHighTemperature,
                  "displayTemp"
                )
              : [];
          // Check if Display Water is 1
          const checkWater =
            displayFlag.water > 0
              ? this.filterResults(
                  checkPressureAndTemp,
                  displayWater,
                  "displayWater"
                )
              : [];
          // Check if Display Gas is 1
          const checkGas =
            displayFlag.gas > 0
              ? this.filterResults(
                  checkPressureAndTemp,
                  displayGas,
                  "displayGas"
                )
              : [];
          // Check if Display Cold is 1
          const checkCold =
            displayFlag.cold > 0
              ? this.filterResults(
                  checkPressureAndTemp,
                  displayRefrigerant,
                  "displayCold"
                )
              : [];
          const checkedTempAndPressure = [
            ...checkTemp,
            ...checkWater,
            ...checkGas,
            ...checkCold,
          ];
          // Check of any custom excludes
          const checkedExcludes = this.filterResults(
            checkedTempAndPressure,
            exclude,
            "brand",
            true
          );
          this.setState({
            filteredResults: checkedExcludes,
            type: type,
            pressure: pressure,
            temp: temp,
            submission: true,
          });
          this.setDataLayer(type, pressure, temp);
        }}
        validate={(values: any) => {
          const errors: FormikErrors<FormValues> = {};
          const inputType = values.type;
          const inputPressure = parseFloat(values.pressure);
          const inputTemp = parseFloat(values.temp);

          if (!inputType) {
            errors.type = "A type is required";
          }
          if (!Number.isInteger(inputPressure)) {
            errors.pressure = "A valid pressure value is required";
          }
          if (!Number.isInteger(inputTemp)) {
            errors.temp = "A valid temperature value is required";
          }
          return errors;
        }}
      >
        {({ errors, values, setFieldValue }) => (
          <>
            {!this.state.submission ? (
              <div className="uk-width-1-1 uk-width-3-4@m uk-width-2-3@l uk-width-1-2@xl uk-margin-auto uk-margin-medium-top">
                <Form
                  id="js-product-selector-form"
                  autoComplete="off"
                  noValidate
                  className="uk-form-stacked uk-text-left"
                >
                  <div className="uk-margin">
                    <FormLabel
                      label="Type of application / medium"
                      forLabel="type"
                      required={true}
                    />
                    <div className="uk-form-controls">
                      <Field
                        className={
                          "uk-select " +
                          (errors.type ? "uk-form-danger" : "uk-form-success")
                        }
                        as="select"
                        id="type"
                        name="type"
                        required
                        value={this.state.type}
                        onChange={(e: any) => {
                          const _target = e.target;
                          this.updateState("type", _target.value);
                          this.setFields(_target, setFieldValue);
                        }}
                      >
                        <option value="">Select Enquiry Type</option>
                        {groupedKeys.map((element: any, index: number) => {
                          return (
                            <optgroup
                              label={element}
                              key={`optgroup_item_${index}`}
                            >
                              {groupedEntries[element].map(
                                (item: any, index: number) => (
                                  <option
                                    key={`option_item_${index}`}
                                    value={item.Title}
                                    data-note={item.Note}
                                    data-lower-pressure={item.LowerPressure}
                                    data-limit-pressure={item.LimitPressure}
                                    data-lower-temperature={
                                      item.LowerTemperature
                                    }
                                    data-limit-temperature={
                                      item.LimitTemperature
                                    }
                                    data-mid-temperature={item.MidTemperature}
                                    data-display-water={item.DisplayWater}
                                    data-display-high-temperature={
                                      item.DisplayHighTemperature
                                    }
                                    data-display-gas={item.DisplayGas}
                                    data-display-refrigerant={
                                      item.DisplayRefrigerant
                                    }
                                    data-exclude={item.Exclude}
                                  >
                                    {item.Title}
                                  </option>
                                )
                              )}
                            </optgroup>
                          );
                        })}
                      </Field>
                      {values.note && (
                        <div className="uk-margin-small-top c-text-xsmall">
                          <span className="c-text-conex-red uk-text-bold">
                            Note:
                          </span>{" "}
                          <span
                            dangerouslySetInnerHTML={{ __html: values.note }}
                          />
                        </div>
                      )}

                      <Field type="hidden" name="note" id="note" />
                      <Field
                        type="hidden"
                        name="lowerTemperature"
                        id="lowerTemperature"
                        value={this.state.lowerTemperature}
                      />
                      <Field
                        type="hidden"
                        name="midTemperature"
                        id="midTemperature"
                        value={this.state.midTemperature}
                      />
                      <Field
                        type="hidden"
                        name="limitTemperature"
                        id="limitTemperature"
                        value={this.state.limitTemperature}
                      />
                      <Field
                        type="hidden"
                        name="lowerPressure"
                        id="lowerPressure"
                        value={this.state.lowerPressure}
                      />
                      <Field
                        type="hidden"
                        name="limitPressure"
                        id="limitPressure"
                        value={this.state.limitPressure}
                      />
                      <Field
                        type="hidden"
                        name="displayWater"
                        id="displayWater"
                        value={this.state.displayWater}
                      />
                      <Field
                        type="hidden"
                        name="displayHighTemperature"
                        id="displayHighTemperature"
                        value={this.state.displayHighTemperature}
                      />
                      <Field
                        type="hidden"
                        name="displayGas"
                        id="displayGas"
                        value={this.state.displayGas}
                      />
                      <Field
                        type="hidden"
                        name="displayRefrigerant"
                        id="displayRefrigerant"
                        value={this.state.displayRefrigerant}
                      />
                      <Field
                        type="hidden"
                        name="exclude"
                        id="exclude"
                        value={this.state.exclude}
                      />
                    </div>
                  </div>
                  <div className="uk-margin">
                    <FormLabel
                      label="Pressure (kPa)"
                      forLabel="pressure"
                      required={true}
                    />
                    <div className="uk-form-controls">
                      <Field
                        className={
                          "uk-input c-required " +
                          (errors.pressure
                            ? "uk-form-danger"
                            : "uk-form-success")
                        }
                        type="number"
                        name="pressure"
                        id="pressure"
                        placeholder="Input number"
                        step="1"
                        pattern="[0-9]{5}"
                        value={this.state.pressure}
                        onChange={(e: any) =>
                          this.updateState("pressure", e.target.value)
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="uk-margin">
                    <FormLabel
                      label="Temperature (&deg;C)"
                      forLabel="temp"
                      required={true}
                    />
                    <div className="uk-form-controls">
                      <Field
                        className={
                          "uk-input c-required " +
                          (errors.temp ? "uk-form-danger" : "uk-form-success")
                        }
                        type="number"
                        name="temp"
                        id="temp"
                        placeholder="Input number"
                        step="1"
                        value={this.state.temp}
                        onChange={(e: any) =>
                          this.updateState("temp", e.target.value)
                        }
                        required
                      />
                    </div>
                  </div>
                  <div
                    className="uk-flex uk-flex-middle uk-grid-small uk-margin-medium"
                    data-uk-grid=""
                  >
                    <div className="uk-width-1-1">
                      <button
                        className="uk-button uk-button-primary uk-width-1-1"
                        type="submit"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            ) : (
              this.ResultsList()
            )}
          </>
        )}
      </Formik>
    );
  }

  // markup
  ResultsList(): JSX.Element {
    return (
      <ul className="uk-grid uk-grid-medium">
        {this.state.filteredResults.length
          ? this.state.filteredResults.map(
              (item: ResultProps, index: number) => (
                <FeatureProductItem
                  key={`product_item_${index}`}
                  brandMediaUrl={item.brandMediaUrl}
                  brandMediaTitle={item.brandMediaTitle}
                  brandMediaWidth={item.brandMediaWidth}
                  productImageUrl={item.imageSrc}
                  productImageTitle={item.imageAlt}
                  pageUrl={item.query}
                  customClass="uk-width-1-1 uk-width-1-2@s uk-width-1-3@m uk-width-1-4@l"
                >
                  {item.displayWater && <Icons iconType="water" />}
                  {item.displayGas && <Icons iconType="gas" />}
                  {item.displayCold && <Icons iconType="cold" />}
                  {item.displayTemp && <Icons iconType="temp" />}
                  <ul className="uk-list uk-list-large uk-margin-medium-top">
                    <li className="js-info-results uk-hidden">
                      <span className="js-result-brand">{item.brand}</span>
                      <span className="js-result-application">
                        {item.displayWater ? "water" : ""}
                        {item.displayGas ? "gas" : ""}
                        {item.displayCold ? "refrigerant" : ""}
                        {item.displayTemp ? "high-temperature" : ""}
                      </span>
                    </li>
                    <li>
                      <strong className="c-text-xsmall uk-display-block">
                        Fitting Material
                      </strong>
                      <span className="uk-text-small">
                        {item.fittingMaterial}
                      </span>
                    </li>
                    <li>
                      <strong className="c-text-xsmall uk-display-block">
                        Operating Temperature Range
                      </strong>
                      <span className="uk-text-small">
                        {item.minTempRange} to {item.maxTempRange}
                      </span>
                    </li>
                    <li>
                      <strong className="c-text-xsmall uk-display-block">
                        Maximum Pressure
                      </strong>
                      <span className="uk-text-small">{item.maxPressure}</span>
                    </li>
                    <li>
                      <strong className="c-text-xsmall uk-display-block">
                        Size Range
                      </strong>
                      <span className="uk-text-small">{item.sizeRange}</span>
                    </li>
                  </ul>
                  <div className="uk-margin-medium-top uk-margin-medium-bottom">
                    <a
                      href={item.query}
                      className="uk-button uk-button-primary uk-width-1-1"
                    >
                      View Range
                    </a>
                  </div>
                </FeatureProductItem>
              )
            )
          : this.NoResults()}
      </ul>
    );
  }

  NoResults(): JSX.Element {
    return (
      <li className="uk-margin-auto uk-text-center uk-text-small uk-margin-large">
        <div>
          There were no results for the application you have selected. <br />
          <strong>
            <Link to="/contact">Contact us</Link>
          </strong>{" "}
          for more information
        </div>
        <button
          className="uk-button uk-button-primary uk-margin"
          type="button"
          onClick={() => this.updateState("submission", false)}
        >
          Search again
        </button>
      </li>
    );
  }

  ResultsBar(): JSX.Element {
    return (
      <div className="uk-container">
        <div className="uk-margin-large-top" data-uk-grid="">
          <h2 className="uk-width-1-4 uk-h5 uk-h4@m uk-h3@l">Results</h2>
          <dl
            className="uk-description-list uk-child-width-1-1 uk-child-width-1-3@s uk-width-expand@l uk-grid-small"
            data-uk-grid=""
          >
            <div>
              <dt className="uk-text-uppercase">Application</dt>
              <dd>{this.state.type}</dd>
            </div>
            <div>
              <dt className="uk-text-uppercase">Pressure (kPa)</dt>
              <dd>{this.state.pressure}</dd>
            </div>
            <div>
              <dt className="uk-text-uppercase">Temperature (&deg;C)</dt>
              <dd>{this.state.temp}</dd>
            </div>
          </dl>
        </div>
      </div>
    );
  }

  Controls(): JSX.Element {
    return (
      <div className="uk-container uk-position-relative">
        {this.state.submission && this.ResetButton()}
        <div className="uk-padding-small uk-position-top-right">
          <button
            className="uk-modal-close uk-icon uk-link"
            type="button"
            onClick={() => this.resetForm()}
          >
            <span className="c-text-xsmall uk-margin-small-right">Close</span>
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ color: "#008765" }}
            >
              <path
                d="M1.5 26.5L26.5 1.5M1.5 1.5L26.5 26.5"
                stroke="#008765"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    );
  }

  ResetButton(): JSX.Element {
    return (
      <div className="uk-padding-small uk-position-top-left">
        <button
          className="uk-icon uk-link"
          type="button"
          onClick={() => this.updateState("submission", false)}
        >
          <svg
            width="19"
            height="36"
            viewBox="0 0 19 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ color: "#008765" }}
          >
            <path
              d="M17.8125 34.666L1.146 17.9995L17.8125 1.33301"
              stroke="#008765"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="c-text-xsmall uk-margin-small-left">Back</span>
        </button>
        <button
          className="uk-icon uk-link uk-margin-large-left"
          type="button"
          onClick={() => this.resetForm()}
        >
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ color: "#008765" }}
          >
            <path
              d="M1.33301 1.33301V11.7497H2.5445M34.5374 15.9163C33.5122 7.69486 26.4989 1.33301 17.9997 1.33301C11.0051 1.33301 5.01695 5.64169 2.5445 11.7497M2.5445 11.7497H11.7497M34.6663 34.6663V24.2497H33.4548M33.4548 24.2497C30.9824 30.3577 24.9942 34.6663 17.9997 34.6663C9.50047 34.6663 2.48716 28.3045 1.46195 20.083M33.4548 24.2497H24.2497"
              stroke="#008765"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="c-text-xsmall uk-margin-small-left">Reset</span>
        </button>
      </div>
    );
  }

  HeaderBar(): JSX.Element {
    return (
      <>
        <div className="uk-text-center uk-margin-large">
          <svg
            width="61"
            height="61"
            viewBox="0 0 61 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 43L26.9969 34.0031M26.9969 34.0031C27.8647 34.8854 28.8986 35.5872 30.039 36.0679C31.1794 36.5486 32.4037 36.7987 33.6413 36.8039C34.8788 36.8091 36.1052 36.5692 37.2495 36.0981C38.3939 35.6269 39.4337 34.9339 40.3089 34.0589C41.1841 33.1839 41.8774 32.1443 42.3488 31C42.8202 29.8558 43.0604 28.6295 43.0555 27.3919C43.0506 26.1544 42.8007 24.93 42.3203 23.7895C41.8398 22.649 41.1383 21.6149 40.2562 20.7469C38.4935 19.0122 36.1166 18.0443 33.6435 18.0541C31.1703 18.0638 28.8012 19.0505 27.0522 20.7991C25.3032 22.5477 24.316 24.9166 24.3056 27.3897C24.2953 29.8629 25.2626 32.2399 26.9969 34.0031ZM58.625 30.5C58.625 34.1934 57.8975 37.8507 56.4841 41.263C55.0707 44.6753 52.999 47.7757 50.3874 50.3874C47.7757 52.999 44.6753 55.0707 41.263 56.4841C37.8507 57.8975 34.1934 58.625 30.5 58.625C26.8066 58.625 23.1493 57.8975 19.737 56.4841C16.3247 55.0707 13.2243 52.999 10.6126 50.3874C8.00097 47.7757 5.9293 44.6753 4.51589 41.263C3.10247 37.8507 2.375 34.1934 2.375 30.5C2.375 23.0408 5.33816 15.8871 10.6126 10.6126C15.8871 5.33816 23.0408 2.375 30.5 2.375C37.9592 2.375 45.1129 5.33816 50.3874 10.6126C55.6618 15.8871 58.625 23.0408 58.625 30.5Z"
              stroke="#008765"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h2 className="uk-h5 uk-h4@m uk-h3@l uk-h2@xl uk-margin-top">
            Product Selector
          </h2>
        </div>
      </>
    );
  }

  Footer(): JSX.Element {
    return (
      <a
        className="uk-link uk-modal-close uk-text-small uk-text-bold"
        type="button"
        onClick={() => this.resetForm()}
      >
        <span className="uk-margin-small-right">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 10.5L1.5 1.5M10.5 1.5L1.5 10.5"
              stroke="#008765"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        Close Product Selector
      </a>
    );
  }

  render(): JSX.Element {
    return (
      <ModalWrapper
        id="js-product-selector-app"
        controls={this.Controls()}
        headerbar={!this.state.submission && this.HeaderBar()}
        resultsbar={this.state.submission && this.ResultsBar()}
        content={this.ProductSelectorForm()}
        footer={this.Footer()}
      />
    );
  }
}

export default ProductSelectorApp;
