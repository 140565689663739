// This DATA comes from a google spreadsheet and is downloaded as
// a CSV and then converted into valid JSON

const options = [
  {
    ID: 1,
    Title: "Hot and cold potable water",
    Note: "Australian Watermark approved. Watermark certification for all plumbing products is restricted to 1400kPa at 95°C",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: 0,
    LimitTemperature: 110,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Water Supply",
    Sort: 1,
  },
  {
    ID: 2,
    Title: "Fire services",
    Note: "Capable of the required test pressure of 1700kPa or 1.5 times the design pressure as specified by AS 2419.1",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: 0,
    LimitTemperature: 110,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Water Supply",
    Sort: 2,
  },
  {
    ID: 3,
    Title: "Rainwater",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: 0,
    LimitTemperature: 40,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Water Supply",
    Sort: 3,
  },
  {
    ID: 4,
    Title: "Pump circulated hot water systems",
    Note: "Compliant with EN 12828",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: 0,
    LimitTemperature: 110,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Water Supply",
    Sort: 4,
  },
  {
    ID: 5,
    Title: "Chilled water and cooling systems",
    Note: "Water and water-glycol mixtures, mixing ratio max. 50/50%",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1000,
    LowerTemperature: -10,
    LimitTemperature: 110,
    DisplayWater: 1,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Water Supply",
    Sort: 5,
  },
  {
    ID: 6,
    Title: "Low pressure steam",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 100,
    LowerTemperature: 0,
    LimitTemperature: 120,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Water Supply",
    Sort: 6,
  },
  {
    ID: 7,
    Title: "Solar collectors",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: 0,
    LimitTemperature: 160,
    DisplayWater: 0,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Water Supply",
    Sort: 7,
  },
  {
    ID: 9,
    Title:
      "Treated, softened, partially and fully \n desalinated water and water treated \n by reverse osmosis",
    Note: "",
    Exclude: "bpress,bpressxl",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: 0,
    LimitTemperature: 95,
    DisplayWater: 1,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Industrial and Process Water",
    Sort: 9,
  },
  {
    ID: 10,
    Title:
      "Treated, softened, partially de-ionised \n water with a pH of 6.5 ≤ 9.5",
    Note: "",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: 0,
    LimitTemperature: 110,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Industrial and Process Water",
    Sort: 10,
  },
  {
    ID: 11,
    Title: "Natural gas",
    Note: "The scope of AS 5601 for all gas systems is restricted to 200kPa.",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1000,
    LowerTemperature: -20,
    LimitTemperature: 70,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 11,
  },
  {
    ID: 12,
    Title: "Liquid Gas Propane Butane Methane",
    Note: "The scope of AS 5601 for all gas systems is restricted to 200kPa.",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1000,
    LowerTemperature: -20,
    LimitTemperature: 70,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 12,
  },
  {
    ID: 13,
    Title: "Compressed air (oil concentration < 25mg/m³)",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 70,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 13,
  },
  {
    ID: 14,
    Title: "Compressed air (oil concentration > 25mg/m³)",
    Note: "",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -20,
    LimitTemperature: 70,
    DisplayWater: 0,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 14,
  },
  {
    ID: 15,
    Title: "Acetylene",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 15,
  },
  {
    ID: 16,
    Title: "Argon",
    Note: "For welding",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 70,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 16,
  },
  {
    ID: 17,
    Title: "Hydrogen",
    Note: "May leak at <0.001cm³ /min",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 500,
    LowerTemperature: -35,
    LimitTemperature: 70,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 17,
  },
  {
    ID: 18,
    Title: "Carbon Monoxide",
    Note: "",
    Exclude: "bpressxl",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 70,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 18,
  },
  {
    ID: 19,
    Title: "Forming gas, dry/welding inert gas (Ar+CO²)",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 70,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 19,
  },
  {
    ID: 20,
    Title: "Oxygen (gas)",
    Note: "Non-medical use only and keep free of oil/grease",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1000,
    LowerTemperature: -35,
    LimitTemperature: 40,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 20,
  },
  {
    ID: 21,
    Title: "Vacuum",
    Note: "Non-medical use only",
    Exclude: "",
    LowerPressure: -80,
    LimitPressure: 0,
    LowerTemperature: -35,
    LimitTemperature: 40,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 21,
  },
  {
    ID: 22,
    Title: "Carbon Dioxide (dry gas)",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 40,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 22,
  },
  {
    ID: 23,
    Title: "Carbon Dioxide (wet gas)",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 40,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 23,
  },
  {
    ID: 24,
    Title: "Nitrogen (gas)",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 70,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 24,
  },
  {
    ID: 25,
    Title: "Helium",
    Note: "May leak at <0.001cm³/min",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 70,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 25,
  },
  {
    ID: 26,
    Title: "Neon",
    Note: "",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 70,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 26,
  },
  {
    ID: 27,
    Title: "Xenon",
    Note: "",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 70,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 27,
  },
  {
    ID: 28,
    Title: "Synthetic air",
    Note: "",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 70,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 28,
  },
  {
    ID: 29,
    Title: "Krypton",
    Note: "",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 70,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Gas",
    Sort: 29,
  },
  {
    ID: 30,
    Title: "Engine oils and lubricants (Petroleum-based)",
    Note: "Consultation required",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1000,
    LowerTemperature: -20,
    LimitTemperature: 70,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Oils, Cooling Materials and Lubricants",
    Sort: 30,
  },
  {
    ID: 31,
    Title: "Engine oils (Synthetic based)",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1000,
    LowerTemperature: -20,
    LimitTemperature: 70,
    DisplayWater: 0,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Oils, Cooling Materials and Lubricants",
    Sort: 31,
  },
  {
    ID: 32,
    Title: "Bio Diesel",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Oils, Cooling Materials and Lubricants",
    Sort: 32,
  },
  {
    ID: 33,
    Title: "Heating oil, Diesel",
    Note: "In accordance with EN590",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 500,
    LowerTemperature: -20,
    LimitTemperature: 40,
    DisplayWater: 0,
    DisplayHighTemperature: 1,
    DisplayGas: 1,
    DisplayRefrigerant: 0,
    GroupTitle: "Oils, Cooling Materials and Lubricants",
    Sort: 33,
  },
  {
    ID: 34,
    Title: "Antifrogen N",
    Note: "Manufacturer: Clariant",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -25,
    LimitTemperature: 160,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Anti-Freeze Cooling Contentation 50%",
    Sort: 34,
  },
  {
    ID: 35,
    Title: "Antifrogen L",
    Note: "Manufacturer: Clariant",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -25,
    LimitTemperature: 160,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Anti-Freeze Cooling Contentation 50%",
    Sort: 35,
  },
  {
    ID: 36,
    Title: "Antifrogen Sol (solar systems)",
    Note: "Manufacturer: Clariant",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -25,
    LimitTemperature: 160,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Anti-Freeze Cooling Contentation 50%",
    Sort: 36,
  },
  {
    ID: 37,
    Title: "Ethlene glycol (Ethan-1,2-diol)",
    Note: "Manufacturer: Various",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -25,
    LimitTemperature: 160,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Anti-Freeze Cooling Contentation 50%",
    Sort: 37,
  },
  {
    ID: 38,
    Title: "Propylene glycol (1,2-Propandiol)",
    Note: "Manufacturer: Various",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -25,
    LimitTemperature: 160,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Anti-Freeze Cooling Contentation 50%",
    Sort: 38,
  },
  {
    ID: 39,
    Title: "Tyfoxit",
    Note: "Manufacturer: Tyforop-Chemie",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -25,
    LimitTemperature: 160,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Anti-Freeze Cooling Contentation 50%",
    Sort: 39,
  },
  {
    ID: 40,
    Title: "Tyfocop",
    Note: "Manufacturer: Tyforop-Chemie",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -25,
    LimitTemperature: 160,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Anti-Freeze Cooling Contentation 50%",
    Sort: 40,
  },
  {
    ID: 41,
    Title: "Condensate",
    Note: "From gas condenser boiler or steam",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 110,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Special Media",
    Sort: 41,
  },
  {
    ID: 42,
    Title: "Acetone (liquid)",
    Note: "",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 500,
    LowerTemperature: -10,
    LimitTemperature: 40,
    DisplayWater: 1,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Special Media",
    Sort: 42,
  },
  {
    ID: 43,
    Title: "Ethanol",
    Note: "",
    Exclude: "bpressstainlesssteel",
    LowerPressure: 0,
    LimitPressure: 1600,
    LowerTemperature: -35,
    LimitTemperature: 25,
    DisplayWater: 1,
    DisplayHighTemperature: 1,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Special Media",
    Sort: 43,
  },
  {
    ID: 44,
    Title: "Urea",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Special Media",
    Sort: 44,
  },
  {
    ID: 45,
    Title: "Methanol",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Special Media",
    Sort: 45,
  },
  {
    ID: 46,
    Title: "Sodium Hydroxide",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Special Media",
    Sort: 46,
  },
  {
    ID: 47,
    Title: "Ammonia",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Special Media",
    Sort: 47,
  },
  {
    ID: 48,
    Title: "R-1234yf",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 48,
  },
  {
    ID: 49,
    Title: "R-1234ze",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 49,
  },
  {
    ID: 50,
    Title: "R-32",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 50,
  },
  {
    ID: 51,
    Title: "R-134a",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 51,
  },
  {
    ID: 52,
    Title: "R-404A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 52,
  },
  {
    ID: 53,
    Title: "R-407C",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 53,
  },
  {
    ID: 54,
    Title: "R-407F",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 54,
  },
  {
    ID: 55,
    Title: "R-410A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 55,
  },
  {
    ID: 56,
    Title: "R-507A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 56,
  },
  {
    ID: 57,
    Title: "R-125",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 57,
  },
  {
    ID: 58,
    Title: "R-407A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 58,
  },
  {
    ID: 59,
    Title: "R-407H",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 59,
  },
  {
    ID: 60,
    Title: "R-417A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 60,
  },
  {
    ID: 61,
    Title: "R-421A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 61,
  },
  {
    ID: 62,
    Title: "R-422B",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 62,
  },
  {
    ID: 63,
    Title: "R-422D",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 63,
  },
  {
    ID: 64,
    Title: "R-427A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 64,
  },
  {
    ID: 65,
    Title: "R-438A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 65,
  },
  {
    ID: 66,
    Title: "R-448A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 66,
  },
  {
    ID: 67,
    Title: "R-449A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 67,
  },
  {
    ID: 68,
    Title: "R-450A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 68,
  },
  {
    ID: 69,
    Title: "R-452A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 69,
  },
  {
    ID: 70,
    Title: "R-452C",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 70,
  },
  {
    ID: 71,
    Title: "R-513A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 71,
  },
  {
    ID: 72,
    Title: "R-513B",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 72,
  },
  {
    ID: 73,
    Title: "R-515B",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 73,
  },
  {
    ID: 74,
    Title: "R-718",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 74,
  },
  {
    ID: 75,
    Title: "R-444A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 75,
  },
  {
    ID: 76,
    Title: "R-447A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 76,
  },
  {
    ID: 77,
    Title: "R-447B",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 77,
  },
  {
    ID: 78,
    Title: "R-452B",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 78,
  },
  {
    ID: 79,
    Title: "R-454A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 79,
  },
  {
    ID: 80,
    Title: "R-454B",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 80,
  },
  {
    ID: 81,
    Title: "R-454C",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 81,
  },
  {
    ID: 82,
    Title: "R-457A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 82,
  },
  {
    ID: 83,
    Title: "R-459A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 83,
  },
  {
    ID: 84,
    Title: "R-290",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 84,
  },
  {
    ID: 85,
    Title: "R-600A",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 85,
  },
  {
    ID: 86,
    Title: "HYCOOL 20",
    Note: "Approved oils: POE, PAO, PVE, AB and mineral oil",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 4800,
    LowerTemperature: -40,
    LimitTemperature: 121,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 1,
    GroupTitle: "Refrigerant",
    Sort: 86,
  },
  {
    ID: 87,
    Title: "R-717",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Refrigerant",
    Sort: 87,
  },
  {
    ID: 88,
    Title: "R-723",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Refrigerant",
    Sort: 88,
  },
  {
    ID: 89,
    Title: "R-764",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Refrigerant",
    Sort: 89,
  },
  {
    ID: 90,
    Title: "R-744",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Refrigerant",
    Sort: 90,
  },
  {
    ID: 91,
    Title: "R-22",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Refrigerant",
    Sort: 91,
  },
  {
    ID: 92,
    Title: "Other Refrigerant",
    Note: "",
    Exclude: "",
    LowerPressure: 0,
    LimitPressure: 0,
    LowerTemperature: 0,
    LimitTemperature: 0,
    DisplayWater: 0,
    DisplayHighTemperature: 0,
    DisplayGas: 0,
    DisplayRefrigerant: 0,
    GroupTitle: "Refrigerant",
    Sort: 92,
  },
];

export default options;
