// assets
import brandBPress from "../../assets/logos/logo-bpress.svg";
import brandBpressXl from "../../assets/logos/logo-bpress-xl.svg";
import brandMaxipro from "../../assets/logos/logo-maxipro.svg";
import brandBpressStainlessSteel from "../../assets/logos/logo-bpress-stainless-steel.svg";
import brandBpressStainlessSteelv2 from "../../assets/logos/logo-bpress-stainless-steel-v2.svg";
import brandRomaxCompact from "../../assets/logos/logo-romax-compact-tt.svg";
import brandRomax4000 from "../../assets/logos/logo-romax-4000.svg";
import brandAccessories from "../../assets/logos/logo-accessories.svg";
import brandApproved from "../../assets/logos/logo-approved-tools.svg";

// assets
import imageBpress from "../../assets/images/products/feature/product-b-press.jpg";
import imageBpressGas from "../../assets/images/products/feature/product-b-press-gas.jpg";
import imageBpressHighTemp from "../../assets/images/products/feature/product-b-press-high-temp.jpg";
import imageBpressxl from "../../assets/images/products/feature/product-b-pressxl.jpg";
import imageBpressxlGas from "../../assets/images/products/feature/product-b-pressxl-gas.jpg";
import imageBpressstainlesssteel from "../../assets/images/products/feature/product-b-pressstainlesssteel.jpg";
import imageBmaxipro from "../../assets/images/products/feature/product-b-maxipro.jpg";
import imageAccessories from "../../assets/images/products/feature/tools-accessories.jpg";
import imageRomaxCompact from "../../assets/images/products/feature/tools-romax-compact-tt.jpg";
import imageRomax4000 from "../../assets/images/products/feature/tools-romax-4000.jpg";
import imageApproved from "../../assets/images/products/feature/tools-approved.jpg";

/*
 * This is used across the components
 * It pulls in the associated brand data
 * The brand is an image as the > and < doesn't look great
 */

// Icon mapping
interface IMapping {
  [key: string]: any;
}

export const BrandAttributes: IMapping = {
  bpress: {
    icon: brandBPress,
    width: "116px",
    title: ">B< Press",
    src: imageBpress,
    srcGas: imageBpressGas,
    srcHighTemp: imageBpressHighTemp,
    url: "/products/b-press",
  },
  "b-press": {
    icon: brandBPress,
    width: "116px",
    title: ">B< Press",
    src: imageBpress,
    url: "/products/b-press",
  },
  bpressxl: {
    icon: brandBpressXl,
    width: "155px",
    title: ">B< Press XL",
    src: imageBpressxl,
    srcGas: imageBpressxlGas,
    url: "/products/b-press-xl",
  },
  "b-press-xl": {
    icon: brandBpressXl,
    width: "155px",
    title: ">B< Press XL",
    src: imageBpressxl,
    url: "/products/b-press-xl",
  },
  bmaxipro: {
    icon: brandMaxipro,
    width: "143px",
    title: ">B< MaxiPro",
    src: imageBmaxipro,
    url: "/products/b-maxipro",
  },
  "b-maxi-pro": {
    icon: brandMaxipro,
    width: "143px",
    title: ">B< MaxiPro",
    src: imageBmaxipro,
    url: "/products/b-maxipro",
  },
  bpressstainlesssteel: {
    icon: brandBpressStainlessSteel,
    width: "310px",
    title: ">B< Press Stainess Steel",
    src: imageBpressstainlesssteel,
    url: "/products/b-press-stainless-steel",
  },
  "b-press-stainless-steel": {
    icon: brandBpressStainlessSteel,
    width: "310px",
    title: ">B< Press Stainess Steel",
    src: imageBpressstainlesssteel,
    url: "/products/b-press-stainless-steel",
  },
  bpressstainlesssteelv2: {
    icon: brandBpressStainlessSteelv2,
    width: "169px",
    title: "Bpress Stainess Steel",
    src: imageBpressstainlesssteel,
    url: "/products/b-press-stainless-steel",
  },
  romaxcompacttt: {
    icon: brandRomaxCompact,
    width: "222px",
    title: "Romax Compact TT",
    src: imageRomaxCompact,
    url: "/tools-and-accessories#romax-compact-tt",
  },
  romax400: {
    icon: brandRomax4000,
    width: "143px",
    title: "Romax 4000",
    src: imageRomax4000,
    url: "/tools-and-accessories#romax-4000",
  },
  accessories: {
    icon: brandAccessories,
    width: "137px",
    title: "Accessories",
    src: imageAccessories,
    url: "/tools-and-accessories#accessories",
  },
  approved: {
    icon: brandApproved,
    width: "181px",
    title: "Approved Tools",
    src: imageApproved,
    url: "/tools-and-accessories#approved-tools",
  },
};
