import React, { Fragment } from "react";
import { Link } from "gatsby";

// Components
import Footer from "./Footer";
import Header from "./Header";
import SkipLinks from "../Content/SkipLinks/Index";
import ProductSelectorApp from "../Content/Product/Selector";

// Global styles
import * as UIkit from "uikit";
import * as Icons from "uikit/dist/js/uikit-icons.js";
import "../../styles/core.scss";

class Layout extends React.Component {
  state = {
    ready: false,
  };

  componentDidMount(): void {
    if (typeof window !== "undefined") {
      UIkit.use(Icons);
      this.setState({ ready: true });
    }
  }

  render(): JSX.Element {
    const { children } = this.props;

    return (
      <Fragment>
        {this.state.ready && (
          <div key="wrapper" id="top" className="site-wrap">
            <SkipLinks />
            <Header />
            <main id="main" className="c-main uk-margin-xlarge-top">
              {children}
            </main>
            <Footer />
            <ProductSelectorApp />
            {process.env.NODE_ENV === "development" ? <DevOnly /> : ""}
          </div>
        )}
      </Fragment>
    );
  }
}

const DevOnly = () => {
  return (
    <div
      className="uk-position-fixed uk-position-small uk-position-bottom-left uk-flex uk-flex-column c-noprint uk-hidden"
      style={{ zIndex: 99 }}
    >
      <a
        className="uk-margin-small-bottom uk-button uk-button-small uk-button-secondary"
        href="#"
      >
        Viewport - <span className="c-debug"></span>
      </a>
      <Link
        to="/uat"
        className="uk-margin-small-bottom uk-button uk-button-small uk-button-primary"
        activeClassName="uk-active"
      >
        UAT
      </Link>
      <Link
        to="/styles"
        className="uk-margin-small-bottom uk-button uk-button-small uk-button-primary"
        activeClassName="uk-active"
      >
        Styles
      </Link>
      <Link
        to="/components"
        className="uk-margin-small-bottom uk-button uk-button-small uk-button-primary"
        activeClassName="uk-active"
      >
        Components
      </Link>
      <a
        // data-uk-scroll="true"
        className="uk-scroll uk-button uk-button-small  uk-button-primary"
        href="#top"
      >
        Scroll up
      </a>
    </div>
  );
};

export default Layout;
