import React from "react";

// Shape of form values
interface PropsLabel {
  label: string;
  forLabel: string;
  required: boolean | false;
}

// markup
const FormLabel: React.FC<PropsLabel> = (props) => {
  return (
    <label className="uk-form-label" htmlFor={props.forLabel}>
      {props.label}
      {props.required && <abbr title="This field is required">*</abbr>}
    </label>
  );
};

export default FormLabel;
