import React from "react";
import { StaticQuery, graphql } from "gatsby";

import ProductNav from "./ProductNav";
import ToolsNav from "./ToolsNav";
import NavbarDropdownArticle from "./NavbarDropdownArticle";
import MobileNav from "./MobileNav";

import { DropdownItem } from "./NavbarDropdownArticle";

import downloadsImage from "../../../assets/menu/thumb-downloads.jpg";
import faqsImage from "../../../assets/menu/thumb-faqs.jpg";
import trainingImage from "../../../assets/menu/thumb-training.jpg";

interface Props {
  readonly data: NavbarQueryData;
}

const Navbar: React.FC<Props> = ({ data }) => {
  let itemsArr: DropdownItem[] | [] = [];
  data.allMarkdownRemark.edges.forEach(({ node }) => {
    const { category, images, slug, title } = node.frontmatter;
    const image = images.length ? images[0] : undefined;

    const dropdownItem: DropdownItem = {
      heading: title,
      subheading: category,
      imagePath: image ? image.path : "",
      imageAlt: image ? image.altText : "",
      linkText: "View Case Study",
      linkPath: slug,
    };

    itemsArr = [...itemsArr, dropdownItem];
  });

  const resourcesItems: DropdownItem[] = [
    {
      heading: "Downloads",
      subheading: "Application guides, technical information and more",
      imagePath: downloadsImage,
      imageAlt: "Rothenburger tool cutting a copper pipe",
      linkText: "Learn More",
      linkPath: "/resources/downloads",
    },
    {
      heading: "FAQs",
      subheading: "Get answers quick. Check out our frequently asked questions",
      imagePath: faqsImage,
      imageAlt: "Copper pipes on a ceiling",
      linkText: "Learn More",
      linkPath: "/resources/faqs",
    },
    {
      heading: "Training",
      subheading: "Get the most out of your press system",
      imagePath: trainingImage,
      imageAlt: "Copper pipes on a ceiling",
      linkText: "Learn More",
      linkPath: "/resources/training",
    },
  ];

  return (
    <>
      <ProductNav />
      <ToolsNav />
      <NavbarDropdownArticle
        linkTextDesktop="Case Studies"
        linkTextMobile="View All Case Studies"
        linkPath="/case-studies"
        items={itemsArr}
      />
      <NavbarDropdownArticle
        linkTextDesktop="Resources"
        linkPath="/resources"
        items={resourcesItems}
      />
      <MobileNav />
    </>
  );
};

// eslint-disable-next-line react/display-name
export default (): JSX.Element => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { published: { ne: false } } }
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 9
        ) {
          edges {
            node {
              excerpt
              frontmatter {
                category
                images {
                  altText
                  path
                }
                slug
                title
              }
            }
          }
        }
      }
    `}
    render={(data) => <Navbar data={data} />}
  />
);

interface ImageData {
  path: string;
  altText: string;
}

interface NavbarQueryData {
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string;
        frontmatter: {
          category: string;
          slug: string;
          title: string;
          images: ImageData[] | [];
        };
      };
    }[];
  };
}
