import React from "react";
import { Link } from "gatsby";

// components
import NavbarDropdownItem from "./NavbarDropdownItem";
import NavbarDropdown from "./NavbarDropdown";

// assets
import bpress from "../../../assets/menu/thumb-bpress.jpg";
import bpressXl from "../../../assets/menu/thumb-bpress-xl.jpg";
import bpressMaxiPro from "../../../assets/menu/thumb-bpress-maxipro.jpg";
import bpressStainless from "../../../assets/menu/thumb-bpress-stainless-steel.jpg";

interface Props {
  id: number;
  imgSrc: string;
  imgAlt: string;
  title: string;
  subtitle: string;
  viewMore: string;
  pageUrl: string;
}

const productItems = [
  {
    id: 1,
    imgSrc: bpress,
    imgAlt: "bpress",
    title: ">B< Press",
    subtitle: "15 - 50mm",
    viewMore: "/products/?brandTitle=>B<+Press",
    pageUrl: "/products/b-press",
  },
  {
    id: 2,
    imgSrc: bpressXl,
    imgAlt: "bpress xl",
    title: ">B< Press XL",
    subtitle: "65 - 100mm",
    viewMore: "/products/?brandTitle=>B<+Press+XL",
    pageUrl: "/products/b-press-xl",
  },
  {
    id: 3,
    imgSrc: bpressMaxiPro,
    imgAlt: "bpress",
    title: ">B< MaxiPro",
    subtitle: "1/4&quot; - 1 3/8&quot;",
    viewMore: "/products/?brandTitle=>B<+MaxiPro",
    pageUrl: "/products/b-maxipro",
  },
  {
    id: 4,
    imgSrc: bpressStainless,
    imgAlt: "bpress",
    title: ">B< Press <br/>Stainless Steel",
    subtitle: "15 - 54mm",
    viewMore: "/products/?brandTitle=>B<+Press+Stainless+Steel",
    pageUrl: "/products/b-press-stainless-steel",
  },
];

const ProductNav: React.FC = () => {
  return (
    <NavbarDropdown
      title="Products"
      url="/products"
      navigation={<ProductNavItems />}
      quicklinks={<QuickLinks />}
    />
  );
};

const ProductNavItems: React.FC = () => {
  return (
    <>
      {productItems.map((item: Props, index: number) => (
        <NavbarDropdownItem
          key={`product_item_${index}`}
          id={`js-scroll-products-${item.id}`}
          imgSrc={item.imgSrc}
          imgAlt={item.imgAlt}
          title={item.title}
          subtitle={item.subtitle}
          viewMore={item.viewMore}
          pageUrl={item.pageUrl}
        />
      ))}
    </>
  );
};

const QuickLinks: React.FC = () => {
  return (
    <>
      {/* item */}
      <div className="c-navigation__item uk-text-center">
        <div className="uk-width-2-3 uk-height-1-1 uk-margin-auto uk-flex uk-flex-middle uk-flex-center">
          <div className="uk-flex-auto">
            <strong className="uk-margin-remove-bottom uk-display-block uk-h6 uk-text-bold">
              Product Selector
            </strong>
            <p className="uk-margin-remove uk-h6 uk-text-small">
              Need help finding the right system for the job?
            </p>
            <a
              href="#js-product-selector-app"
              data-uk-toggle=""
              className="uk-display-inline-block uk-text-bold uk-text-small uk-link c-icon-search"
            >
              Launch Selector
            </a>
          </div>
        </div>
        <div className="c-navigation__divider" />
      </div>
      {/* item */}
      <div className="c-navigation__item uk-text-center">
        <div className="uk-width-2-3 uk-height-1-1 uk-margin-auto uk-flex uk-flex-middle uk-flex-center">
          <div className="uk-flex-auto">
            <strong className="uk-margin-remove-bottom uk-display-block uk-h6 uk-text-bold">
              FAQs
            </strong>
            <p className="uk-margin-remove uk-h6 uk-text-small">
              Get answers quick. Check out our frequently asked questions
            </p>
            <Link
              to="/resources/faqs"
              className="uk-display-inline-block uk-text-bold uk-text-small uk-link c-icon-right-arrow"
            >
              Find Out More
            </Link>
          </div>
        </div>
        <div className="c-navigation__divider uk-visible@l" />
      </div>
    </>
  );
};

export default ProductNav;
