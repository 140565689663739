import React from "react";

// assets
import coldIcon from "../../../assets/icons/icon-cold.svg";
import gasIcon from "../../../assets/icons/icon-hot.svg";
import waterIcon from "../../../assets/icons/icon-drop.svg";
import tempIcon from "../../../assets/icons/icon-temp.svg";

interface Props {
  iconType:
    | "gas"
    | "cold"
    | "refrigerant"
    | "temp"
    | "high-temperature"
    | "water";
  iconSize?: "xsmall" | "small" | "medium";
  customTitle?: string;
}

interface IMapping {
  [key: string]: any;
}

const defaultProps: IMapping = {
  iconType: undefined,
  iconSize: "small",
};

const iconMapping: IMapping = {
  gas: {
    icon: gasIcon,
    title: "Gas",
    tooltip: "Application - Gas",
  },
  cold: {
    icon: coldIcon,
    title: "Refrigerant",
    tooltip: "Application - Refrigerant",
  },
  refrigerant: {
    icon: coldIcon,
    title: "Refrigerant",
    tooltip: "Application - Refrigerant",
  },
  water: {
    icon: waterIcon,
    title: "Water",
    tooltip: "Application - Water",
  },
  temp: {
    icon: tempIcon,
    title: "Temp",
    tooltip: "Application - High Temperature",
  },
  "high-temperature": {
    icon: tempIcon,
    title: "High Temperature",
    tooltip: "Application - High Temperature",
  },
};

const Icons: React.FC<Props> = (props) => {
  const { iconType, iconSize, customTitle } = props;
  const icon = iconMapping[iconType] || [];
  const title = customTitle || icon?.title;

  return (
    <>
      {icon && (
        <div className="uk-flex uk-flex-middle">
          <div
            className={`c-icontips c-icontips__${iconType} c-icontips__${iconSize} uk-border-circle uk-text-center uk-inline`}
            uk-tooltip={
              iconSize !== "xsmall" ? `title: ${icon?.tooltip}` : undefined
            }
          >
            <img src={icon?.icon} alt={`${icon?.title} Icon`} />
          </div>

          {iconSize === "xsmall" && (
            <span className="uk-text-uppercase c-text-3xsmall c-text-letter-spacing-large">
              {title}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default Icons;

Icons.defaultProps = defaultProps;
