import React from "react";

interface Props {
  controls: React.ReactNode;
  headerbar: React.ReactNode;
  resultsbar: React.ReactNode;
  content: React.ReactNode;
  footer: React.ReactNode;
  id?: string;
}

// markup
const ModalWrapper: React.FC<Props> = ({
  controls,
  headerbar,
  resultsbar,
  content,
  footer,
  id,
}) => {
  return (
    <div
      className="uk-modal-full c-noprint"
      style={{ zIndex: 100 }}
      id={id}
      data-uk-modal="container: false"
    >
      <div className="uk-modal-dialog" data-uk-height-viewport="">
        <section className="uk-section uk-section-small uk-section-muted">
          <div className="uk-container">{controls}</div>
        </section>
        <section className="uk-section uk-section-small uk-section-muted">
          <div className="uk-container">
            {headerbar}
            {resultsbar}
          </div>
        </section>
        <section className="uk-section uk-padding-remove-bottom">
          <div className="uk-container">{content}</div>
        </section>
        <div className="uk-modal-footer uk-text-center uk-background-default uk-padding-large uk-padding-remove-top">
          {footer}
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
